import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import Footer from './components/footer/Footer';

ReactDOM.render(
  <BrowserRouter>
    {/* 
    import Navroutes from './components/navroutes/Navroutes';
       <Navroutes />
    */}
    <App />
    <Footer />
  </BrowserRouter>,
  document.getElementById("root")
);