import "./app.scss"
import { Routes, Route } from "react-router-dom"
import ComingSoon from "./pages/main/ComingSoon";

export default function App() {
  return (
    <div className="app">
      <Routes className="sections">
        <Route path="/" element={<ComingSoon />} />
        {/* 
                import Services from "./pages/services/Services";
import Contact from "./pages/contact/Contact";
import Main from "./pages/main/Main";
                 <Route path="/" element={<Main />} />
                <Route path="services" element={<Services />} />
                <Route path="contact" element={<Contact />} />
                */}
      </Routes>
    </div>
  );
}
